import Service from "../Service";
const resource = "FrzCtnPalletsDiscartLot/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    getLotFilterDiscart(obj, requestID) {
        return Service.post(resource + "getLotFilterDiscart", obj, {
            params: {  requestID: requestID },
        });
    },

    savePallet(obj, requestID) {
        return Service.post(resource + "savePallet", obj, {
            params: { requestID: requestID },
        });
    },
    listPallet( requestID) {
        return Service.post(resource + "listPallet", {}, {
            params: { requestID: requestID },
        });
    },
    removePallet(obj, requestID) {
        return Service.post(resource + "removePallet", obj, {
            params: { requestID: requestID },
        });
    },
    saveDiscartVP(obj, requestID) {
        return Service.post(resource + "saveDiscartVP", obj, {
            params: { requestID: requestID },
        });
    },
    saveDiscartCork(obj, requestID) {
        return Service.post(resource + "saveDiscartCork", obj, {
            params: { requestID: requestID },
        });
    },
    listDetailPallets(obj, requestID) {
        return Service.post(resource + "listDetailPallets", obj, {
            params: { requestID: requestID },
        });
    },
    emptyPallet(obj, requestID) {
        return Service.post(resource + "emptyPallet", obj, {
            params: { requestID: requestID },
        });
    },
    UpdatePalletDiscart(obj, requestID) {
        return Service.post(resource + "UpdatePalletDiscart", obj, {
            params: { requestID: requestID },
        });
    },
    AnulateDiscart(obj, requestID) {
        return Service.post(resource + "AnulateDiscart", obj, {
            params: { requestID: requestID },
        });
    },
    GetDetailQR(obj, requestID) {
        return Service.post(resource + "GetDetailQR", obj, {
            params: { requestID: requestID },
        });
    },
    getlotproductionselectionpalletsend(obj, requestID) {
        return Service.post(resource + "getlotproductionselectionpalletsend", obj, {
            params: { requestID: requestID },
        });
    },
    sendConditioned(obj, requestID) {
        return Service.post(resource + "sendConditioned", obj, {
            params: { requestID: requestID },
        });
    },
}